import { Link } from "gatsby"
import React from "react"
import YAMLData from "../../content/navigation/main-nav.yml"

import {
  wrap,
  wrapMobileVisible,
} from "./mainNav.module.css"

import {
  item,
  itemLink,
  itemLinkActive,
} from "./navItem.module.css"

import { NavItem } from "./navItem"

export function MainNav({ mobileVisible }) {

  return (
    <ul className={ mobileVisible ? wrapMobileVisible : wrap }>
      {YAMLData.navItems.map((navItem, index) => {
        if (navItem.navItemType === 'shop') {
          return (
            <li className={item} key="nav-item-shop">
              <Link to={`/products`} className={itemLink} activeClassName={itemLinkActive}>{navItem.navItemTitle}</Link>
            </li>
          )
        }
        return (
          <NavItem navItemType={navItem.navItemType} navItemTitle={ navItem.navItemTitle } key={`nav-item${index}`} />
        )
      })}
    </ul>
  )
}
