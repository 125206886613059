import React from "react"
import YAMLData from "../../content/navigation/footer-nav.yml"

import {
  wrap,
  wrapMobileVisible,
} from "./footerNav.module.css"

import { NavItem } from "./navItem"

export function FooterNav({ mobileVisible }) {
  return (
    <ul className={ mobileVisible ? wrapMobileVisible : wrap}>
      {YAMLData.navItems.map((navItem, index) => {
          return (
            <NavItem navItemType={`flat`} navItemTitle={ navItem.navItemTitle } navItemLink={ navItem.navItemLink } key={`nav-item-footer${index}`} />
          )
      })}
    </ul>
  )
}