import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { useLocation } from "@reach/router"
import {
  item,
  itemLink,
  itemLinkActive,
  sublist,
  sublistVisible,
  subitem,
  subitemLink,
  subitemLinkActive,
} from "./navItem.module.css"

export function NavItem(...props) {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
        edges {
          node {
            frontmatter {
              title
              date(formatString: "DD.MM.YYYY")
              type
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

const { navItemType, navItemTitle, navItemLink } = props[0];
const allPageEdges = data.allMarkdownRemark.edges;
const location = useLocation();
let subnavItemIsActive = false;

const subnavItems = allPageEdges.filter((subItem) => {
  if (subItem.node.frontmatter.type === navItemType) {
    return subItem.node
  }
})

subnavItems.forEach(subItem => {
  if (subItem.node.fields.slug === location.pathname) {
    subnavItemIsActive = true;
  }
})

const [isVisible, setIsVisible] = useState(false);

const toggleVisible = () => {
  setIsVisible(!isVisible);
};
if (subnavItems.length) {
    return (
      <li onClick={toggleVisible} className={item}>
        <p className= { subnavItemIsActive || isVisible ? itemLinkActive : itemLink }>{navItemTitle}</p>
        {/* EXAMPLE: Direct Link to first child */}
        {/* <Link to={`${subnavItems[0].node.fields.slug}`} onClick={toggleVisible}>{navItemTitle}</Link> */}
        <ul className={ isVisible || subnavItemIsActive ? sublistVisible : sublist }>
          {subnavItems.map((subItem) => {
            if (subItem.node.frontmatter.type === navItemType) {
              return (
                <li className={subitem} key={`subnav-item-${subItem.node.frontmatter.title}`}>
                  <Link to={`${subItem.node.fields.slug}`} className={ subitemLink } activeClassName={subitemLinkActive}>{ subItem.node.frontmatter.date ? subItem.node.frontmatter.date + ' ' : '' }{ subItem.node.frontmatter.title }</Link>
                </li>
                )
              }
              return ''
            })}
        </ul>
      </li>
    )
  } else if (navItemType === 'flat') {
    return (
      <li className={item} key={`flat-nav-item-${navItemTitle}`}>
        <Link to={`/${navItemLink}`} className={ itemLink } activeClassName={ itemLinkActive }>{ navItemTitle }</Link>
      </li>
      )
  }
  return
}
